import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/userSlice';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            dispatch(setUser(JSON.parse(storedUser)));
        }
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDocSnap = await getDoc(userDocRef);
                const { firstName, lastName, email, phoneNumber, points } = userDocSnap.data();
                const userData = {
                    uid: user.uid,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    points: points
                };
                setCurrentUser(userData);
                setLoading(false);
                dispatch(setUser(userData));
                localStorage.setItem('user', JSON.stringify(userData));
            }
            else {
                setCurrentUser(null)
                dispatch(setUser(null));
                setLoading(false)
                localStorage.removeItem('user');
            }
        });
        return () => unsubscribe;
    }, [dispatch]);

    const signIn = async (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

     const signOutUser = async () => {
        setLoading(true);
        try {
            await signOut(auth);
        } catch (error) {
        } finally {
            localStorage.removeItem('user');
            setLoading(false);
        }
    };
    
    const value = {
        currentUser,
        signIn,
        signOutUser,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
